// 1.Dependency
import { createStore } from "vuex";
import { getUserInfo } from "@/service/account";
import { apps } from "@/assets/data/config/app";
import User from "@iruxu/rx-common/utils/user";

// 2.Store
const store = {
    state: {
        user: {},

        navExpanded: true,

        app: "miipet",

        lang: User.getLocale(),
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_NAV(state, val) {
            state.navExpanded = val;

            localStorage.setItem("nav_expanded", state.navExpanded);
        },
        SET_APP(state, val) {
            state.app = val;
        },
        SET_LOCALE(state, val) {
            state.lang = val;
        },
    },
    getters: {
        user: (state) => state.user,
        appLabel: (state) => {
            const app = apps.find((item) => item.value === state.app);
            return app ? app.label : "";
        },
        app: (state) => state.app,
    },
    actions: {
        async getProfile({ commit }) {
            const res = await getUserInfo();
            commit("SET_USER", res.data.data);
            // 保存用户信息
            User._save(res.data.data);
            return res.data.data;
        },
    },
};

export default createStore(store);
