export default {
    nav: {
        index: "资料设置",
        address: "地址管理",
        user: "个人资料",
        info: "基本信息",
        profile: "我的资料",
        connect: "账号互联",
    },
    address: {
        add: "新增地址",
        edit: "编辑地址",
        name: "联系人",
        namePlaceholder: "请输入联系人",
        phone: "联系电话",
        phonePlaceholder: "请输入联系电话",
        addr: "联系地址",
        addrPlaceholder: "请输入联系地址",
        tag: "标签",
        school: "学校",
        home: "家",
        company: "公司",
        custom: "自定义",
        isDefault: "默认地址",
        defaultTag: "默认",
        setDefault: "设为默认",
        cancelDefault: "取消默认",
    },
    info: {
        avatar: "头像",
        nickname: "昵称",
        email: "邮箱",
        phone: "手机",
        locale: "语言偏好",
        nicknameRequired: "请输入昵称",
        nicknameExist: "昵称已存在",
    },
    profile: {
        country: "国家",
        birth: "生日",
        bio: "签名",
        birthPlaceholder: "请选择生日",
    },
};
