export default {
    nav: {
        index: "Settings",
        user: "Account",
        address: "Address",
        info: "Basic",
        profile: "Profile",
        connect: "Connect",
    },
    address: {
        add: "Add new address",
        edit: "Edit Address",
        name: "Name",
        namePlaceholder: "Please enter a contact person",
        phone: "Phone Number",
        phonePlaceholder: "Please enter your contact number",
        addr: "Address",
        addrPlaceholder: "Please enter the contact address",
        tag: "Tag",
        school: "School",
        home: "Home",
        company: "Company",
        custom: "Custom",
        isDefault: "Default Address",
        defaultTag: "Default",
        setDefault: "Set as Default",
        cancelDefault: "Cancel default",
    },
    info: {
        avatar: "Avatar",
        nickname: "Nickname",
        email: "Email",
        phone: "Phone",
        locale: "Language",
        nicknameRequired: "Please enter a nickname",
        nicknameExist: "Nickname already exists",
    },
    profile: {
        country: "Country",
        birth: "Birthday",
        bio: "Bio",
        birthPlaceholder: "Please select your birthday",
    },
};
