export default {
    // Application
    app: {
        miipet: "米宠",
        "2kog": "2KOG",
    },

    // Route Name
    routes: {
        // Account
        usernameLogin: "登录 - 用户名登录",
        usernameRegister: "注册 - 用户名注册",

        emailLogin: "登录 - 邮箱登录",
        emailRegister: "注册 - 邮箱注册",
        emailResetPassword: "重置密码 - 邮箱重置",

        phoneRegister: "注册 - 手机注册",
        phoneLogin: "登录 - 手机登录",
        phoneCodeLogin: "登录 - 验证码登录",
        phoneResetPassword: "重置密码 - 手机重置",

        registerCallback: "注册成功",
        loginCallback: "登录成功",

        // Notification
        messageTitle: "消息",
        messageDesc: "来自所有应用的系统消息",

        emailTitle: "邮箱绑定",
        emailDesc: "绑定您的邮箱以接收重要通知",

        phoneTitle: "手机绑定",
        phoneDesc: "绑定您的手机以接收重要通知",

        // Document
        termsTitle: "服务条款",

        // Security
        logTitle: "日志审计",
        logDesc: "来自所有应用的日志",
        deviceTitle: "设备管理",
        deviceDesc: "管理您的所有登录设备",
        passwordTitle: "修改密码",
        passwordDesc: "修改您的登录密码",
        destroyTitle: "注销账号",
        destroyDesc: "您确定要注销您的账号吗？",

        // Profile
        addressTitle: "地址管理",
        addressDesc: "在此管理您的地址",

        userTitle: "个人资料",
        userDesc: "管理您的用户设置",

        infoTitle: "基本信息",
        infoDesc: "管理您的基本信息",

        profileTitle: "个人资料",
        profileDesc: "管理您的个人资料",

        connectTitle: "账号互联",
        connectDesc: "管理您的账号关联",
    },

    // messagebox
    messagebox: {
        title: "温馨提示",
        success: "操作成功",
        error: "操作失败",
        confirm: "确定",
        cancel: "取消",
        copySuccess: "复制成功",
    },

    table: {
        add: "新增",
        edit: "编辑",
        refresh: "刷新",
        search: "搜索",
        start_time: "开始日期",
        end_time: "结束日期",
        op: "操作",
        copy: "复制",
        remark: "备注",
        remark_placeholder: "请输入备注",
        remove: "移除",
        removeAlert: "确定要移除该条记录吗？",
        shortcuts: {
            week: "近一周",
            month: "近一月",
            month_3: "近三月",
        },
    },
};
