import common from "./common";
import notification from "./notification";
import account from "./account";
import security from "./security";
import setting from "./setting";

export default {
    common,
    notification,
    account,
    security,
    setting,
};
